import React from 'react';

// import "../styles/stylesNoPage.css";

function NoPage() 
{
  return (
    <>
      <div>
			  <p>Sorry, no page found.</p>
		  </div>
    </>
  )
};

export default NoPage;